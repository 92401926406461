@import "~@/assets/base/styles.less";

@font-face{
  font-family: "-text-regular";
  src: url("./assets/font/regular.woff");
}
@font-face{
  font-family: "-text-bold";
  src: url("./assets/font/bold.woff");
}
@font-face {
  font-family: "-text-geistVF";
  src: url("./assets/font/geistVF.ttf");
}

body, 
html{
  overscroll-behavior: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: white;
  &::-webkit-scrollbar {
    display: none;
  }
}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
  font-family: -text-geistVF;
}
body, p, div, li {
  line-height: 1.25;
}
body {
  background-color: white;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset, img {
  border: 0;
}
ol, ul, li {
  list-style: none;
}
q:before, q:after {
  content: "";
}
abbr, acronym { 
  border: 0; 
}
a:-webkit-any-link {
  text-decoration: none;
}
// p {
//   font-family: -text-regular;
// }
// h1 {
//   color: black;
//   font-family: -text-bold;
// }
// h2 {
//   color: black;
//   font-family: -text-regular;
// }
// h3 {
//   color: #666666;
//   font-family: -text-bold;
// }
// h4 {
//   color: #666666;
//   font-family: -text-regular;
// }
button {
  width: fit-content;
  border-radius: 50px;
  border-style: none;
  font-family: -text-bold;
  padding: 16px 48px;
  font-size: 20px;
  cursor: pointer;
}
@media screen and (max-width: @APP-SCREEN) {
  button {
    padding: 10px 20px;
    font-size: 16px;
  }
}