@import "~@/assets/base/styles.less";

.payment {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  position: relative;
  @media screen and (max-width: @APP-SCREEN) {
    flex-direction: column;
  }

  .lang-div {
    position: absolute;
    top: 40px;
    right: 60px;
    padding: 8px 12px;
    border: 1px solid black;
    font-size: 16px;
    font-weight: 700;
    color: black;
    cursor: pointer;
    @media screen and (max-width: @APP-SCREEN) {
      top: 14px;
      right: 16px;
      border-color: white;
      color: white;
    }
  }
  .leftDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 38%;
    min-height: 100vh;
    background-color: black;
    @media screen and (max-width: @APP-SCREEN) {
      width: 100%;
      min-height: 0;
      height: 64px;
    }
  
    .backDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 170px 60px;
      cursor: pointer;
      @media screen and (max-width: @APP-SCREEN) {
        width: 100%;
        height: 100%;
        margin: 0;
      }
  
      img {
        width: 16px;
        @media screen and (max-width: @APP-SCREEN) {
          margin-left: 24px;
        }
      }
      p {
        margin: 0;
        margin-left: 12px;
        color: white;
        font-size: 16px;
      }
    }
    .logoDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 60px;
      @media screen and (max-width: @APP-SCREEN) {
        display: none;
      }
  
      p {
        margin: 0;
        margin-right: 8px;
        color: rgba(white, 0.5);
        font-size: 14px;
      }
      img {
        height: 18px;
      }
    }
  }
  .rightDiv {
    width: 62%;
    min-height: 100vh;
    background-color: white;
    padding: 160px 144px 160px 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: @APP-SCREEN) {
      width: 100%;
      padding: 32px 16px;
    }

    .stepDiv {
      display: flex;
      flex-direction: row;
      align-items: center;

      .number {
        background-color: black;
        font-size: 20px;
        color: white;
        width: 34px;
        height: 34px;
        border-radius: 34px;
        line-height: 34px;
        text-align: center;
        @media screen and (max-width: @APP-SCREEN) {
          width: 32px;
          height: 32px;
          font-size: 16px;
        }
      }
      .sessionTitle {
        margin-left: 16px;
        font-size: 24px;
        font-weight: 700;
        color: black;
        @media screen and (max-width: @APP-SCREEN) {
          margin-left: 12px;
          font-size: 16px;
        }
      }
    }
    .selectContainer {
      margin: 6px 0;
      margin-left: 16px;
      border-left: 1px solid black;
      padding-bottom: 40px;

      .selectTitle {
        margin-top: 24px;
        margin-left: 32px;
        font-size: 16px;
        color: black;
      }
      .selectDiv {
        width: calc(100% - 32px);
        height: 56px;
        margin-left: 32px;
        margin-top: 12px;
        border: 1px solid black;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .select-icon {
          width: 22px;
          border-radius: 22px;
          margin-right: 10px;
        }
        .select-token {
          font-size: 20px;
          color: black;
          flex: 1;
          @media screen and (max-width: @APP-SCREEN) {
            font-size: 16px;
          }
        }
        .select-tag {
          width: 16px;
        }
      }
    }
    .copyContainer {
      width: calc(100% - 50px);
      margin-left: 50px;
      margin-top: 32px;
      border: 1px solid black;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      box-sizing: border-box;

      .copyText {
        font-size: 20px;
        color: black;
        margin-right: 16px;
        word-break: break-all;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 16px;
        }
      }
      .copyButton {
        padding: 8px 16px;
        border: 1px solid black;
        border-radius: 36px;
        font-weight: 700;
        font-size: 16px;
        color: black;
        white-space: nowrap;
        cursor: pointer;
      }
    }
    .descDiv {
      width: calc(100% - 50px);
      margin-left: 50px;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: @APP-SCREEN) {
        flex-direction: column;
      }

      .qrcodeDiv {
        width: 216px;
        height: 216px;
        border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .contentDiv {
        margin-left: 24px;
        flex: 1;
        background: rgba(255, 214, 64, 0.05);
        border: 1px solid rgba(255, 214, 64, 0.5);
        padding: 24px 16px;
        box-sizing: border-box;
        @media screen and (max-width: @APP-SCREEN) {
          margin-top: 24px;
          margin-left: 0;
        }

        p {
          font-size: 14px;
          color: black;

          span {
            color: #FAAF00;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.payment-modal-class .ant-modal {
  width: 568px !important;

  .ant-modal-body {
    padding: 32px 0;
  }
  .toolContainer {
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: black;
    }
    .close-button {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .select-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 32px 0;
    
    .select-item {
      width: 100%;
      height: 56px;
      padding: 0 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .select-icon {
        width: 24px;
        border-radius: 24px;
        margin-right: 8px;
      }
      .select-token {
        font-size: 20px;
        color: black;
        flex: 1;
      }
      .select-tag {
        width: 24px;
      }
    }
  }
}