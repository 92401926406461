@import "~@/assets/base/styles.less";

.foot-component {
  width: 100%;
  padding: 48px @PADDING-PC;
  box-sizing: border-box;
  background-color: black;
  display: flex;
  flex-direction: column;

  .logo-session {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    img {
      height: 36px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
        margin-left: 24px;
      }
    }
  }
  .desc {
    width: 50%;
    margin-top: 24px;
    font-size: 16px;
    color: white;
    line-height: 1.5;
  }
  .lang-session {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 64px;

    h2 {
      font-size: 16px;
      color: rgba(white, 0.6);
      flex: 1;
      margin-right: 20px;
      line-height: 1.5;
    }
    .button {
      border: 1px solid white;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px;
      border-radius: 56px;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
      h1 {
        font-size: 16px;
        color: white;
      }
    }
  }
}
@media screen and (max-width: @APP-SCREEN) {
  .foot-component {
    width: 100%;
    padding: 30px @PADDING-APP;
    box-sizing: border-box;
    background-color: black;
    display: flex;
    flex-direction: column;
  
    .logo-session {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  
      img {
        height: 25px;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
  
        img {
          width: 25px;
          height: 25px;
          cursor: pointer;
          margin-left: 12px;
        }
      }
    }
    .desc {
      width: 100%;
      margin-top: 24px;
      font-size: 14px;
      color: white;
      line-height: 1.5;
    }
    .lang-session {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 34px;
  
      h2 {
        font-size: 12px;
        color: rgba(white, 0.6);
        flex: 1;
        margin-right: 20px;
        line-height: 1.5;
      }
      .button {
        border: 1px solid white;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        border-radius: 56px;
        cursor: pointer;
  
        img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
        h1 {
          font-size: 14px;
          color: white;
        }
      }
    }
  }
}