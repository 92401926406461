@import "~@/assets/base/styles.less";

.head-component {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  padding: 32px @PADDING-PC;
  box-sizing: border-box;

  .body-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    img {
      height: 40px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .start-button {
        border: 1px solid black;
        background-color: white;
        color: black;
        margin-left: 16px;
      }
      .wallet-button {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
          height: 24px;
          margin-left: 6px;
        }
      }
    }
  }
}
@media screen and (max-width: @APP-SCREEN) {
  .head-component {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    padding: 20px @PADDING-APP;
    box-sizing: border-box;

    .body-div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      img {
        height: 20px;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
  
        .start-button {
          border: 1px solid black;
          background-color: white;
          color: black;
          padding: 8px 10px;
          font-size: 12px;
          margin-left: 5px;
        }
        .wallet-button {
          background-color: transparent;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 8px 8px;
          font-size: 12px;
  
          img {
            width: 14px;
            height: 14px;
            margin-left: 2px;
          }
        }
      }
    }
  }
}