@import "~@/assets/base/styles.less";

.modal-close-button {
  width: 24px;
  height: 24px;
}
.modal-class .ant-modal {
  width: 648px !important;

  .ant-modal-body {
    padding: 32px 24px;
  }
  .body-div {
    width: 100%;
    display: flex;
    flex-direction: column;

    .t1 {
      font-size: 24px;
    }
    .t2 {
      font-size: 16px;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    .session-name {
      font-size: 16px;
      margin-top: 24px;
    }
    .session-input {
      width: 100%;
      border: 1px solid black;
      box-sizing: border-box;
      margin-top: 12px;
      padding: 10px 16px;
      font-size: 20px;
      color: black;
      font-family: -text-regular;
    }
    .ant-input:focus, 
    .ant-input-focused,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input-number:focus, 
    .ant-input-number-focused {
      box-shadow: 0 0 0 1px transparent !important;
      border-color: #FAAF00 !important;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, 
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      border-color: #E01D0B !important;
    }
    .button {
      height: 56px;
      border-radius: 56px;
      border-style: none;
      font-family: -text-bold;
      padding: 0 48px;
      font-size: 20px;
      color: white;
      background-color: black;
      margin-top: 32px;
    }
  }
}
@media screen and (max-width: @APP-SCREEN) {
  .modal-class .ant-modal {
    width: 350px !important;
  
    .ant-modal-body {
      padding: 20px 12px;
    }
    .body-div {
      width: 100%;
      display: flex;
      flex-direction: column;
  
      .t1 {
        font-size: 18px;
      }
      .t2 {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 4px;
      }
      .session-name {
        font-size: 12px;
        margin-top: 16px;
      }
      .session-input {
        width: 100%;
        border: 1px solid black;
        box-sizing: border-box;
        margin-top: 8px;
        padding: 6px 8px;
        font-size: 16px;
        color: black;
        font-family: -text-regular;
      }
      .ant-input:focus, 
      .ant-input-focused,
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused,
      .ant-input-number:focus, 
      .ant-input-number-focused {
        box-shadow: 0 0 0 1px transparent !important;
        border-color: #FAAF00 !important;
      }
      .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, 
      .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        border-color: #E01D0B !important;
      }
      .button {
        height: 40px;
        border-radius: 40px;
        border-style: none;
        font-family: -text-bold;
        padding: 0 20px;
        font-size: 16px;
        color: white;
        background-color: black;
        margin-top: 20px;
      }
    }
  }
}