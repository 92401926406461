@import "~@/assets/base/styles.less";

.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;

  .session1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid black;

    .content-div {
      width: 51%;
      display: flex;
      flex-direction: column;
      padding: 0 24px 0 @PADDING-PC;

      .t1 {
        font-size: 64px;
        letter-spacing: -1.92px;
      }
      .t2 {
        margin-top: 24px;
        font-size: 24px;
        line-height: 1.5;
      }
      button {
        color: white;
        background-color: black;
        margin-top: 40px;
      }
      .list {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 72px;

        .item {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 48px;
            height: 48px;
          }
          h4 {
            margin-left: 16px;
            font-size: 16px;
            margin-right: 32px;
            white-space: pre-wrap;
          }
        }
      }
    }
    .image {
      width: 49%;
      height: 1080px;
      object-fit: cover;
      border-left: 1px solid black;
      box-sizing: border-box;
    }
  }
  .session2 {
    width: 100%;
    padding: 140px @PADDING-PC;
    background-color: #FFD640;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;

    .t1 {
      font-size: 48px;
      letter-spacing: -0.96px;
    }
    .t2 {
      margin-top: 32px;
      font-size: 20px;
      line-height: 1.5;
      width: 75%;
      box-sizing: border-box;
    }
    .list {
      width: 100%;
      margin-top: 72px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        width: calc(25% - 18px);
        height: 0;
        margin: 0 24px 24px 0;
        padding-bottom: calc(25% - 18px);
        border: 1px solid black;
        box-sizing: border-box;
        position: relative;

        &:nth-child(4n + 4) {
          margin-right: 0;
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
        }
        h1 {
          color: white;
          font-size: 24px;
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 24px;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          bottom: 32px;
          text-align: center;
        }
      }
    }
  }
  .session3 {
    width: 100%;
    padding: 140px @PADDING-PC;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .t1 {
      font-size: 48px;
      letter-spacing: -0.96px;
    }
    .t2 {
      font-size: 20px;
      margin-top: 32px;
      line-height: 1.5;
      width: 75%;
    }
    .list {
      width: 100%;
      margin-top: 72px;
      display: flex;
      flex-direction: row;

      .item {
        flex: 1;
        margin-right: 24px;

        &:nth-child(4n + 4) {
          margin-right: 0;
        }
        img {
          width: 100%;
          border: 1px solid black;
        }
        h1 {
          margin-top: 32px;
          font-size: 24px;
          white-space: pre-wrap;
        }
        h4 {
          margin-top: 16px;
          font-size: 20px;
          line-height: 1.5;
        }
      }
    }
  }
  .session4 {
    width: 100%;
    padding: 140px @PADDING-PC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .image {
      width: 41%;
      margin-left: 8px;
    }
    .content-div {
      width: 49%;
      display: flex;
      flex-direction: column;

      .t1 {
        font-size: 48px;
        letter-spacing: -0.96px;
      }
      .t2 {
        font-size: 20px;
        margin-top: 32px;
        line-height: 1.5;
      }
      .list {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 72px;

        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 32px;

          &:last-child {
            margin-right: 0;
          }
          img {
            width: 48px;
          }
          h4 {
            font-size: 16px;
            margin-left: 16px;
          }
        }
      }
    }
  }
  .session7 {
    width: 100%;
    padding: 120px @PADDING-PC 160px @PADDING-PC;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .image {
      width: 38%;
      margin-left: 60px;
    }
    .content-div {
      width: 45%;
      display: flex;
      flex-direction: column;
      z-index: 2;

      .t1 {
        font-size: 48px;
        letter-spacing: -0.96px;
      }
      .t2 {
        font-size: 20px;
        line-height: 1.5;
        margin-top: 32px;
      }
    }
  }
  .session8 {
    width: 100%;
    padding: 140px 228px;
    box-sizing: border-box;
    background-color: #FFD9DB;
    display: flex;
    flex-direction: column;
    align-items: center;

    .t1 {
      font-size: 48px;
      letter-spacing: -0.96px;
      text-align: center;
    }
    button {
      color: white;
      background-color: black;
      margin-top: 64px;
    }
  }
  .session9 {
    width: 100%;
    padding: 140px 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .t1 {
      font-size: 48px;
      letter-spacing: -0.96px;
    }
    .t2 {
      font-size: 20px;
      line-height: 1.5;
      margin-top: 32px;
      text-align: center;
    }
    .list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 60px;

      img {
        height: 60px;
        margin: 4px 15px;
      }
    }
  }
}
@media screen and (max-width: @APP-SCREEN) {
  .home-page {
    width: 100%;
    display: flex;
    flex-direction: column;
  
    .session1 {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      border-bottom: 1px solid black;
  
      .content-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px @PADDING-APP;
        box-sizing: border-box;
  
        .t1 {
          font-size: 28px;
          letter-spacing: -0.96px;
        }
        .t2 {
          margin-top: 16px;
          font-size: 16px;
          line-height: 1.5;
        }
        button {
          color: white;
          background-color: black;
          margin-top: 32px;
          padding: 14px 40px
        }
        .list {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 42px;
          margin-bottom: 50px;
  
          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
  
            img {
              width: 30px;
              height: 30px;
            }
            h4 {
              margin-left: 6px;
              margin-right: 0;
              font-size: 12px;
              white-space: pre-wrap;
            }
          }
        }
      }
      .image {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-left-style: none;
      }
    }
    .session2 {
      width: 100%;
      padding: 56px @PADDING-APP;
      background-color: #FFD640;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;
  
      .t1 {
        font-size: 28px;
      }
      .t2 {
        margin-top: 16px;
        font-size: 16px;
        line-height: 1.5;
        width: 100%;
        box-sizing: border-box;
      }
      .list {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
  
        .item {
          width: calc(50% - 9px);
          height: 0;
          margin: 0 18px 18px 0;
          padding-bottom: calc(50% - 9px);
          border: 1px solid black;
          box-sizing: border-box;
          position: relative;
  
          &:nth-child(2n + 2) {
            margin-right: 0;
          }
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
          }
          h1 {
            color: white;
            font-size: 16px;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            bottom: 12px;
            text-align: center;
          }
        }
      }
    }
    .session3 {
      width: 100%;
      padding: 56px @PADDING-APP;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
  
      .t1 {
        font-size: 28px;
      }
      .t2 {
        font-size: 16px;
        margin-top: 20px;
        line-height: 1.5;
        width: 100%;
      }
      .list {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
  
        .item {
          flex: 1;
          margin-right: 0;
          margin: 28px 0;
          
          img {
            width: 100%;
            border: 1px solid black;
          }
          h1 {
            margin-top: 24px;
            font-size: 20px;
            white-space: normal;
          }
          h4 {
            margin-top: 16px;
            font-size: 16px;
            line-height: 1.5;
          }
        }
      }
    }
    .session4 {
      width: 100%;
      padding: 56px @PADDING-APP;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
  
      .image {
        width: 80%;
        margin-left: 0;
        margin-top: 16px;
      }
      .content-div {
        width: 100%;
        display: flex;
        flex-direction: column;
  
        .t1 {
          font-size: 28px;
        }
        .t2 {
          font-size: 16px;
          margin-top: 20px;
          line-height: 1.5;
        }
        .list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          margin-top: 30px;
          margin-bottom: 50px;
  
          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 0;
            margin: 5px 0;

            img {
              width: 30px;
            }
            h4 {
              font-size: 12px;
              margin-left: 12px;
              text-align: left;
            }
          }
        }
      }
    }
    .session7 {
      width: 100%;
      padding: 56px @PADDING-APP;
      box-sizing: border-box;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
  
      .image {
        width: 80%;
        margin-left: 0;
        margin-top: 32px;
        z-index: 2;
      }
      .content-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 2;
  
        .t1 {
          font-size: 28px;
        }
        .t2 {
          font-size: 16px;
          line-height: 1.5;
          margin-top: 20px;
        }
      }
    }
    .session8 {
      width: 100%;
      padding: 56px @PADDING-APP;
      box-sizing: border-box;
      background-color: #FFD9DB;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .t1 {
        font-size: 28px;
        text-align: center;
      }
      button {
        color: white;
        background-color: black;
        margin-top: 30px;
        padding: 14px 40px;
      }
    }
    .session9 {
      width: 100%;
      padding: 56px @PADDING-APP;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .t1 {
        font-size: 28px;
      }
      .t2 {
        font-size: 16px;
        line-height: 1.5;
        margin-top: 16px;
        text-align: center;
      }
      .list {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
  
        img {
          height: 30px;
          margin: 4px 8px;
        }
      }
    }
  }
}