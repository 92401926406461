@import "~@/assets/base/styles.less";

.m_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  z-index: 10;

  &_content {
    width: 100%;
    max-width: 1440px;
    padding: 0 80px;
    box-sizing: border-box;
    @media screen and (max-width: @APP-SCREEN) {
      padding: 0 16px;
    }
  }
}
.root-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  .m_header_container {
    background-color: black;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .m_header {
    width: 100%;
    height: 84px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: @APP-SCREEN) {
      height: 64px;
    }

    .logo {
      height: 32px;
      @media screen and (max-width: @APP-SCREEN) {
        height: 24px;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      @media screen and (max-width: @APP-SCREEN) {
        column-gap: 4px;
      }

      button {
        padding: 8px 16px;
        border-radius: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 6px;
        @media screen and (max-width: @APP-SCREEN) {
          padding: 7px 16px;
        }

        p {
          font-size: 16px;
          font-weight: 700;
					letter-spacing: -0.16px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
      .lang {
        background-color: transparent;
        color: white;

        p {
          @media screen and (max-width: @APP-SCREEN) {
            display: none;
          }
        }
      }
      .start {
        background-color: white;
        color: black;
      }
    }
  }
  .m_session1_container {
    background-color: black;
  }
  .m_session1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      font-size: 80px;
      color: white;
      font-weight: 900;
      margin-top: 128px;
      text-align: center;
      line-height: 1.12;
      text-transform: uppercase;
      letter-spacing: -4px;
      white-space: pre-wrap;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 40px;
        margin-top: 48px;
				letter-spacing: -2px;
      }
    }
    &_content {
      margin-top: 32px;
      font-size: 24px;
      color: white;
      text-align: center;
      white-space: pre-wrap;
      line-height: 1.35;
			letter-spacing: -0.24px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        margin-top: 16px;
        white-space: normal;
				letter-spacing: -0.16px;
      }
    }
    &_buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;

      button {
        font-size: 20px;
        font-weight: 700;
        padding: 16px 48px;
        border-radius: 56px;
        color: black;
        background-color: white;
        border: 1px solid white;
        margin-top: 40px;
        letter-spacing: -0.2px;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 16px;
          padding: 14px 20px;
          margin: 24px 0;
          letter-spacing: -0.16px;
        }

        &:last-child {
          background-color: black;
          color: white;
        }
      }
    }
    &_image {
      width: 100%;
      margin-top: -56px;
      @media screen and (max-width: @APP-SCREEN) {
        width: 100vw;
        margin-top: -20px;
      }
    }
  }
  .m_session4 {
    width: 100%;
    margin-top: 128px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 64px;
    @media screen and (max-width: @APP-SCREEN) {
      margin-top: 96px;
      flex-direction: var(--direction, column);
      column-gap: 0;
      align-items: flex-start;
    }

    &_div {
      width: calc(50% - 32px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (max-width: @APP-SCREEN) {
        width: 100%;
      }
    }
    &_title {
      font-size: 48px;
      color: black;
      font-weight: 900;
      line-height: 1.12;
      text-transform: uppercase;
      letter-spacing: -2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 28px;
				letter-spacing: -0.84px;
      }
    }
    &_content {
      margin-top: 24px;
      margin-bottom: 16px;
      font-size: 20px;
      color: black;
      line-height: 1.35;
			letter-spacing: -0.2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        margin-top: 12px;
        margin-bottom: 0;
				letter-spacing: -0.16px;
      }
    }
    &_item {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      column-gap: 12px;

      img {
        width: 16px;
        height: 16px;
      }
      p {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.60);
        line-height: 1.35;
				letter-spacing: -0.16px;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 14px;
					letter-spacing: -0.14px;
        }
      }
    }
    &_image {
      width: calc(50% - 32px);

      @media screen and (max-width: @APP-SCREEN) {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
  .m_session5 {
    width: 100%;
    margin-top: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: @APP-SCREEN) {
      margin-top: 96px;
    }

    &_title {
      font-size: 48px;
      color: black;
      font-weight: 900;
      text-align: center;
      line-height: 1.12;
      text-transform: uppercase;
      letter-spacing: -2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 28px;
				letter-spacing: -0.84px;
      }
    }
    &_content {
      margin-top: 24px;
      font-size: 20px;
      color: black;
      text-align: center;
      white-space: pre-wrap;
      line-height: 1.35;
			letter-spacing: -0.2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        margin-top: 12px;
        white-space: normal;
				letter-spacing: -0.16px;
      }
    }
    button {
      font-size: 20px;
      font-weight: 700;
      padding: 16px 48px;
      border-radius: 56px;
      color: white;
      background-color: black;
      margin-top: 32px;
			letter-spacing: -0.2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        padding: 14px 32px;
        margin-top: 16px;
				letter-spacing: -0.16px;
      }
    }
    img {
      width: 100%;
      max-width: 1080px;
      margin-top: 64px;
      margin-bottom: 128px;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 32px;
        margin-bottom: 48px;
      }
    }
  }
  .m_session6_container {
    background-color: black;
  }
  .m_session6 {
    width: 100%;
    padding: 128px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 64px;
    @media screen and (max-width: @APP-SCREEN) {
      padding-top: 48px;
      padding-bottom: 65px;
      flex-direction: column-reverse;
      column-gap: 0;
      align-items: flex-start;
    }

    &_div {
      width: calc(50% - 32px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (max-width: @APP-SCREEN) {
        width: 100%;
      }
    }
    &_title {
      font-size: 64px;
      color: white;
      font-weight: 900;
      line-height: 1.12;
      text-transform: uppercase;
      letter-spacing: -2.56px;
      @media screen and (max-width: @APP-SCREEN) {
        letter-spacing: -1.44px;
        font-size: 36px;
      }
    }
    &_content {
      margin-top: 32px;
      font-size: 20px;
      color: white;
      line-height: 1.35;
			letter-spacing: -0.2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        margin-top: 16px;
				letter-spacing: -0.16px;
      }
    }
    &_list {
      margin-top: 32px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 32px;
      row-gap: 20px;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 24px;
        flex-direction: column;
        row-gap: 16px;
      }
    }
    &_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 16px;
      @media screen and (max-width: @APP-SCREEN) {
        column-gap: 12px;
      }

      img {
        width: 48px;
        height: 48px;
      }
      p {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.60);
        white-space: pre-wrap;
				letter-spacing: -0.16px;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 14px;
          white-space: normal;
					letter-spacing: -0.14px;
        }
      }
    }
    &_image {
      width: calc(50% - 32px);
      @media screen and (max-width: @APP-SCREEN) {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
  .m_session7 {
    width: 100%;
    margin-top: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: @APP-SCREEN) {
      margin-top: 48px;
    }

    &_title {
      font-size: 64px;
      font-weight: 900;
      color: black;
      line-height: 112%; 
      letter-spacing: -2.56px;
      @media screen and (max-width: @APP-SCREEN) {
        letter-spacing: -1.44px;
        font-size: 36px;
        text-align: center;
      }
    }
    &_content {
      font-size: 20px;
      color: black;
      line-height: 1.35;
      margin-top: 32px;
      width: 100%;
      max-width: 680px;
      text-align: center;
			letter-spacing: -0.2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        margin-top: 16px;
				letter-spacing: -0.16px;
      }
    }
    &_carousel {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      pointer-events: none;
      overscroll-behavior: none;
      scrollbar-width: none;
      -ms-overflow-style: none;
      background-color: white;
      &::-webkit-scrollbar {
        display: none;
      }
      @media screen and (max-width: @APP-SCREEN) {
        width: 100vw;
      }
    }
    &_list {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 64px 0;
      column-gap: var(--g);
      transform: translateX(calc(var(--l) * -1px));
      @media screen and (max-width: @APP-SCREEN) {
        padding: 32px 0;
      }
    }
    &_item {
      width: var(--w);
      min-width: var(--w);
      transform: scale(var(--scale));
      position: relative;

      img {
        width: 100%;
      }
      p {
        position: absolute;
        left: 18px;
        bottom: 12px;
        font-size: 20px;
        color: white;
        font-weight: 700;
        letter-spacing: -0.56px;
        white-space: pre-wrap;
				letter-spacing: -0.2px;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 14px;
          left: 10px;
          bottom: 6px;
					letter-spacing: -0.14px;
        }
      }
    }
    &_dot {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 16px;
      margin-top: -44px;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 0;
      }
      
      img {
        width: 36px;
        height: 36px;
        cursor: pointer;
      }
    }
    &_process {
      width: 436px;
      height: 2px;
      background: rgba(0, 0, 0, 0.10);
      position: relative;

      &_active {
        position: absolute;
        left: 0;
        top: 0;
        width: var(--w, 0);
        height: 2px;
        background-color: black;
      }
    }
  }
  .m_session8 {
    width: 100%;
    margin-top: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: @APP-SCREEN) {
      margin-top: 64px;
    }

    &_title {
      font-size: 64px;
      font-weight: 900;
      color: black;
      line-height: 112%; 
      letter-spacing: -2.56px;
      @media screen and (max-width: @APP-SCREEN) {
        letter-spacing: -1.44px;
        font-size: 36px;
        text-align: center;
      }
    }
    &_content {
      font-size: 20px;
      color: black;
      line-height: 1.35;
      margin-top: 32px;
      width: 100%;
      max-width: 680px;
      text-align: center;
			letter-spacing: -0.2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        margin-top: 16px;
				letter-spacing: -0.16px;
      }
    }
    &_list {
      width: 100%;
      margin-top: 64px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 32px;
      row-gap: 8px;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 32px;
        column-gap: 16px;
        row-gap: 4px;
      }

      img {
        height: 60px;
        @media screen and (max-width: @APP-SCREEN) {
          height: 30px;
        }
      }
    }
    &_carousel {
      width: 100%;
      margin-top: 128px;
      display: flex;
      flex-direction: row;
      border-radius: 24px;
      overflow: hidden;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 96px;
        flex-direction: column;
      }
    }
    &_image {
      width: 50%;
      @media screen and (max-width: @APP-SCREEN) {
        width: 100%;
      }
    }
    &_div {
      width: 50%;
      background-color: #FFD640;
      padding: 0 48px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      row-gap: 24px;
      @media screen and (max-width: @APP-SCREEN) {
        width: 100%;
        padding: 16px;
        row-gap: 12px;
      }

      &_head {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 16px;
        @media screen and (max-width: @APP-SCREEN) {
          column-gap: 12px;
        }
      }
      &_logo {
        width: 48px;
        height: 48px;
        @media screen and (max-width: @APP-SCREEN) {
          width: 40px;
          height: 40px;
        }
      }
      &_label {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
      }
      &_name {
        font-size: 16px;
        color: black;
        font-weight: 700;
				letter-spacing: -0.16px;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 14px;
					letter-spacing: -0.14px;
        }
      }
      &_content {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
				letter-spacing: -0.14px;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 12px;
        }
      }
      &_desc {
        font-size: 32px;
        color: black;
        font-weight: 700;
				letter-spacing: -1.28px;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 20px;
					letter-spacing: -0.2px;
        }
      }
    }
  }
  .m_session9_container {
    background-color: transparent;
  }
  .m_session9 {
    width: 100%;
    margin: 128px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: @APP-SCREEN) {
      margin: 64px 0;
    }

    &_title {
      font-size: 64px;
      font-weight: 900;
      color: black;
      line-height: 112%; 
      letter-spacing: -2.56px;
      white-space: pre-wrap;
      text-align: center;
      @media screen and (max-width: @APP-SCREEN) {
        letter-spacing: -1.44px;
        font-size: 36px;
        white-space: normal;
      }
    }
    &_form {
      width: 100%;
      margin-top: 80px;
      display: flex;
      flex-direction: row;
      border: 1px solid #E6E6E6;
      border-radius: 24px;
      @media screen and (max-width: @APP-SCREEN) {
        flex-wrap: wrap;
        overflow: hidden;
      }

      .form_item {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 32px;
        @media screen and (max-width: @APP-SCREEN) {
          width: 100%;
          flex: none;
        }

        &:first-child {
          border-right: 1px solid #E6E6E6;

          .form_item_type {
            border-top-left-radius: 24px;
            @media screen and (max-width: @APP-SCREEN) {
              border-top-left-radius: 0;
            }
          }
        }
        &:last-child {
          border-left: 1px solid #E6E6E6;

          .form_item_type {
            border-top-right-radius: 24px;
            color: #FFD640;
            @media screen and (max-width: @APP-SCREEN) {
              border-top-right-radius: 0;
            }
          }
        }
        &:nth-child(3) {
          background-color: #FFD640;
          border-radius: 24px;
          border: 1px solid #FFF;
          box-shadow: 0px 4px 32px 0px rgba(255, 214, 64, 0.70);
          margin: -16px 0;
          padding: 16px 24px;
          @media screen and (max-width: @APP-SCREEN) {
            margin: 0;
          }

          .form_item_type {
            background-color: #FFD640;
            color: black;
            border-bottom: 1px solid black;
            padding-left: 0;
          }
          .form_item_content {
            padding-left: 0;
          }
        }
        &_type {
          width: 100%;
          padding: 24px;
          box-sizing: border-box;
          background-color: black;
          font-size: 20px;
          color: white;
          font-weight: 700;
          letter-spacing: -0.2px;
        }
        &_content {
          width: 100%;
          padding: 0 24px;
          display: flex;
          flex-direction: column;
          gap: 4px;

          .form_value {
            font-size: 16px;
            color: black; 
            letter-spacing: -0.16px;
            @media screen and (max-width: @APP-SCREEN) {
              font-size: 14px;
            }
          }
          .form_key {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.60);
            letter-spacing: -0.16px;
            @media screen and (max-width: @APP-SCREEN) {
              font-size: 14px;
            }
          }
          .form_value_strong {
            font-size: 48px;
            font-weight: 700;
            letter-spacing: -1.92px;
            margin-top: -8px;
            @media screen and (max-width: @APP-SCREEN) {
              font-size: 30px;
            }

            span {
              font-size: 24px;
              letter-spacing: -0.96px;
            }
          }

          &:last-child {
            margin-bottom: 32px;
          }
        }
      }
    }
    &_content {
      margin-top: 56px;
      font-size: 20px;
      color: black;
      line-height: 1.35;
      letter-spacing: -0.2px;
      width: 100%;
      max-width: 680px;
      text-align: center;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        margin-top: 28px;
				letter-spacing: -0.16px;
      }
    }
    button {
      font-size: 20px;
      font-weight: 700;
      padding: 16px 48px;
      border-radius: 56px;
      color: white;
      background-color: black;
			letter-spacing: -0.2px;
      margin-top: 40px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        padding: 14px 32px;
				letter-spacing: -0.16px;
        margin-top: 20px;
      }
    }
  }
  .m_session10_container {
    background-color: #FFD640;
    // margin-top: -64px;
    z-index: 1;
  }
  .m_session10 {
    padding: 128px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    @media screen and (max-width: @APP-SCREEN) {
      row-gap: 32px;
    }

    &_title {
      font-size: 48px;
      font-weight: 900;
      color: black;
      line-height: 112%; 
      letter-spacing: -2px;
      white-space: pre-wrap;
      text-align: center;
      @media screen and (max-width: @APP-SCREEN) {
        letter-spacing: -0.84px;
        font-size: 28px;
        white-space: normal;
      }
    }
    button {
      font-size: 20px;
      font-weight: 700;
      padding: 16px 48px;
      border-radius: 56px;
      color: white;
      background-color: black;
			letter-spacing: -0.2px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 16px;
        padding: 14px 32px;
				letter-spacing: -0.16px;
      }
    }
  }
  .m_footer_container {
    background-color: #FFD640;
    border-top: 1px solid black;
  }
  .m_footer {
    width: 100%;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    @media screen and (max-width: @APP-SCREEN) {
      padding: 48px 0;
      row-gap: 32px;
    }

    &_content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 80px;
      @media screen and (max-width: @APP-SCREEN) {
        flex-direction: column;
      }
    }
    &_logo {
      width: 250px;
      @media screen and (max-width: @APP-SCREEN) {
        width: auto;
        height: 32px;
      }
    }
    &_t1 {
      width: 100%;
      max-width: 640px;
      font-size: 16px;
      color: black;
      line-height: 1.5;
			letter-spacing: -0.16px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 14px;
        margin-top: 16px;
				letter-spacing: -0.14px;
      }
    }
    &_t2 {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      margin-left: 330px;
			letter-spacing: -0.16px;
      @media screen and (max-width: @APP-SCREEN) {
        margin-left: 0;
        font-size: 12px;
      }
    }
    &_email {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 6px;
      padding: 8px 16px;
      border: 1px solid black;
      border-radius: 30px;
      cursor: pointer;
      @media screen and (max-width: @APP-SCREEN) {
        padding: 6px 8px;
        margin-top: 32px;
      }

      &_image1 {
        width: 20px;
        height: 20px;
      }
      &_image2 {
        width: 16px;
        height: 16px;
      }
      p {
        font-size: 16px;
        font-weight: 700;
        color: black;
        white-space: nowrap;
				letter-spacing: -0.16px;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 14px;
					letter-spacing: -0.14px;
        }
      }
    }
  }
} 